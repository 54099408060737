@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+PL:wght@100..400&display=swap');

.AppBg{
    text-align: center;
    background: url("assets/flower_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(4px);
    -webkit-filter: blur(2.5px);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
}
.App {
    text-align: center;
}


.font2_light {
    font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}
.font2_semibold {
    font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

.calligraphy-font {
  font-family: "Playwrite PL", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    /*background-color: #282c34;*/
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.names {
    z-index: 1;
    position: relative;
    border-radius: 10px;
    .name{
        color: #fff;
    }
    padding: 10px;
    margin: 20px auto;
    width: 160px;
    div[aria-label="animation"] {
        margin: -15px !important;
    }
}
.date{
    position: relative;
    background-color: rgba(255, 99, 99, 0.25);
    padding: 2px;
    font-size: 21px;
    color: #fff;
}
.location{
    position: absolute;
    bottom: 0;
    background-color: #DDD;
    padding: 15px;
    border-right: 7px;
    box-shadow: 0 5px 15px 10px lightpink;
    line-height: 32px;
    font-size: 18px;
    max-width: 480px;
    span {
        color: #777;
        width: 100%;
        height: 32px;
        /*border-bottom: 1px solid #AAA;*/
    }
}
h4, h3, h2{
    margin: 0;
}
.d-flex{
    display: flex;
}


.page-card{
    width: 300px;
    margin: 50px 70px 50px auto;
    height: 600px;
    overflow: hidden;
    position: relative;
    background: url(assets/wed2.jpg) center no-repeat;
    background-size: cover;
    border-radius: 12px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, .24), 0 17px 50px 0 rgba(0, 0, 0, .19);
    &:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        position: absolute;
        background: rgba(90, 93, 165, .6);
        background: linear-gradient(to bottom, rgba(90, 93, 165, .9), rgba(0, 0, 0, .50))
    }
}

.text-white{
    color: white;;
}

.sub-text{
    text-shadow: 3px 3px 2px rgb(141, 27, 126);
}

.strong{
    font-weight: 700;
}

.countdown-group{
    position: relative;
    display: flex;
    padding: 20px 0;
    flex: 1;
    width: 100%;
    color: #fff;
    align-items: center;

    span{
        &:nth-child(1){
            background-color: rgba(0,0,0,.55);
        }
        &:nth-child(2){
            background-color: rgba(0,0,0,.40);
        }
        &:nth-child(3){
            background-color: rgba(0,0,0,.25);
        }
        &:nth-child(4){
            background-color: rgba(0,0,0,.10);
        }
        margin: 5px;
        border-radius: 100%;
        width: 64px;
        height: 64px;
        line-height: 52px;
        background-color: rgba(0,0,0,.15);
        display: block;
        font-weight: 700;
        small {
            font-weight: 400;
            margin-top: -30px;
            display: block;
        }
    }
}

button{
    background-color: transparent;
    border-width: 0;
    position: relative;
    z-index: 99;
}

.maps-button {
    height: 4em;
    width: 12em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0px solid black;
    cursor: pointer;
    margin: 0 auto;
}

.wrapper {
    height: 2em;
    width: 8em;
    position: relative;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    font-size: 17px;
    z-index: 1;
    color: #000;
    padding: 4px 12px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.7);
    transition: all 0.5s ease;
}

.flower {
    display: grid;
    grid-template-columns: 1em 1em;
    position: absolute;
    transition: grid-template-columns 0.8s ease;
}

.flower1 {
    top: -12px;
    left: -13px;
    transform: rotate(5deg);
}

.flower2 {
    bottom: -5px;
    left: 8px;
    transform: rotate(35deg);
}

.flower3 {
    bottom: -15px;
    transform: rotate(0deg);
}

.flower4 {
    top: -14px;
    transform: rotate(15deg);
}

.flower5 {
    right: 11px;
    top: -3px;
    transform: rotate(25deg);
}

.flower6 {
    right: -15px;
    bottom: -15px;
    transform: rotate(30deg);
}

.petal {
    height: 1em;
    width: 1em;
    border-radius: 40% 70% / 7% 90%;
    background: linear-gradient(#07a6d7, #93e0ee);
    border: 0.5px solid #96d1ec;
    z-index: 0;
    transition: width 0.8s ease, height 0.8s ease;
}

.two {
    transform: rotate(90deg);
}

.three {
    transform: rotate(270deg);
}

.four {
    transform: rotate(180deg);
}

.maps-button:hover .petal {
    background: linear-gradient(#0761d7, #93bdee);
    border: 0.5px solid #96b4ec;
}

.maps-button:hover .flower {
    grid-template-columns: 1.5em 1.5em;
}

.maps-button:hover .flower .petal {
    width: 1.5em;
    height: 1.5em;
}

.maps-button:hover .text {
    background: rgba(255, 255, 255, 0.4);
}

.maps-button:hover div.flower1 {
    animation: 15s linear 0s normal none infinite running flower1;
}

@keyframes flower1 {
    0% {
        transform: rotate(5deg);
    }

    100% {
        transform: rotate(365deg);
    }
}

.maps-button:hover div.flower2 {
    animation: 13s linear 1s normal none infinite running flower2;
}

@keyframes flower2 {
    0% {
        transform: rotate(35deg);
    }

    100% {
        transform: rotate(-325deg);
    }
}

.maps-button:hover div.flower3 {
    animation: 16s linear 1s normal none infinite running flower3;
}

@keyframes flower3 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.maps-button:hover div.flower4 {
    animation: 17s linear 1s normal none infinite running flower4;
}

@keyframes flower4 {
    0% {
        transform: rotate(15deg);
    }

    100% {
        transform: rotate(375deg);
    }
}

.maps-button:hover div.flower5 {
    animation: 20s linear 1s normal none infinite running flower5;
}

@keyframes flower5 {
    0% {
        transform: rotate(25deg);
    }

    100% {
        transform: rotate(-335deg);
    }
}

.maps-button:hover div.flower6 {
    animation: 15s linear 1s normal none infinite running flower6;
}

@keyframes flower6 {
    0% {
        transform: rotate(30deg);
    }

    100% {
        transform: rotate(390deg);
    }
}


@media screen and (max-width: 640px) {
    .page-card{
        margin: 20px auto;
    }
}
